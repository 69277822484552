import React from 'react';

import { FaCheck } from 'react-icons/fa';
import styled from 'styled-components';

const StyledCheck = styled(FaCheck)`
    margin-right: 10px;
    position: relative;
    top: 3px;
`

const Checkmark = () => {
    return <StyledCheck color="green"/>;
};

export default Checkmark;
