import React from 'react';

import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';

const StyledTimes = styled(FaTimes)`
    margin-right: 10px;
    position: relative;
    top: 7px;
`

const X = () => {
    return <StyledTimes size="1.3em" color="red"/>;
};

export default X;
