import React, {useState} from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import Checkbox from './checkbox';
import {Link, useStaticQuery, graphql} from 'gatsby';

const commissionOptions = [
    {label: "Sketch", value: 'sketch'},
    {label: "Coloured Sketch", value: 'sketch_colour'},
    {label: "Flat Colour", value: 'flat_colour'},
    {label: "Soft Shading", value: 'soft_shading'},
    {label: "Rendering", value: 'rendering'},
    {label: "Reference Sheet", value: 'reference_sheet'},
    {label: "Other", value: 'other'},
    /*{
        label: "Sketches",
        options: [
            {label: "Waist Up", value: 'sketch_waist_up'},
            {label: "Full Body", value: 'sketch_full_body'},
        ],
    },
    {
        label: "Flat Colour",
        options: [
            {label: "Waist Up", value: 'flat_colour_waist_up'},
            {label: "Full Body", value: 'flat_colour_full_body'},
        ],
    },*/
    /*{
        label: "Shading",
        options: [
            {label: "Waist Up (simple)", value: 'shading_waist_up_simple'},
            {label: "Full Body (simple)", value: 'shading_full_body_simple'},
            {label: "Waist Up (detailed)", value: 'shading_waist_up_detailed'},
            {label: "Full Body (detailed)", value: 'shading_full_body_detailed'},
        ],
    },*/
];

const Form = styled.form`
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 30px auto;
    padding: 20px;
    border-radius: 8px;
    border: solid rgb(123, 123, 123);
`

const FormElement = styled.label`
    padding: 10px;
    margin: 0;
    color: grey;

    input[type="text"],
    input[type="email"],
    input[type="number"],
    textarea {
        outline: none;
        background: rgba(0, 0, 0, 0.1);
        border: 0;
        border-radius: 4px;
        padding: 12px 20px;
        box-sizing: border-box;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        min-height: 40px;
    }

    input[type="button"] {
        width: auto;
    }
`

const Hidden = styled.input`
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
    z-index: -1;
    cursor: pointer;
`

const SubmitButton = styled.input`
    display:inline-block;
    position: relative;
    padding:0.3em 1.2em;
    margin:0 0.1em 0.1em 0;
    border:0.16em solid rgba(255,255,255,0);
    border-radius:2em;
    box-sizing: border-box;
    text-decoration:none;
    font-weight:300;
    color:#FFFFFF;
    text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35);
    text-align:center;
    transition: all 0.2s;
    background-color: var(--accent-color);
    cursor: pointer;

    :hover {
        border-color: rgba(0, 0, 0, 1);
    }
`

const Header = styled.h2`
    margin-top: 0;
`

const selectStyles = {
    control: (provided) => ({
        ...provided,
        background: "rgba(0, 0, 0, 0.1)",
    }),
};

const CommisionForm = () => {
	const [nsfw, setNsfw] = useState('sfw');
	const [background, setBackground] = useState('no');

    const { config } = useStaticQuery(graphql`
        {
            config: configJson {
                commissions_open
            }
        }
    `)

    if (!config.commissions_open) {
        return (
            <Form id="commission">
                <Header>
                    {'Commissions are closed.'}
                </Header>
                <p>
                    {'Follow me on '}
                    <a href="https://twitter.com/koboldskye">twitter</a>
                    {' to get notified when I open commissions!'}
                </p>
            </Form>
        );
    }

    return (
        <Form id="commission" name="commission" method="POST" data-netlify="true" netlify-honeypot="fullname" action="/submitsuccess">
            <input type="hidden" name="form-name" value="commission" />
            <Hidden type="text" name="fullname"/>
            <Header>{'Request a Commission: '}</Header>
            <FormElement>
                {'Name: '}
                <input type="text" name="name" required/>
            </FormElement>
            <FormElement>
                {'Contact Info (email, Discord, FA username, Twitter handle): '}
                <input type="text" name="contact" required/>
            </FormElement>
            <FormElement>
                {'Paypal email: '}
                <input type="email" name="paypalemail" required/>
            </FormElement>
            <FormElement>
                {'Commission Type: '}
                <Select
                    name="commision_type"
                    options={commissionOptions}
                    styles={selectStyles}
                    required
                />
            </FormElement>
            <FormElement>
                {'Number of characters: '}
                <input type="number" name="number_of_characters" max={4} min={1} defaultValue={1}/>
            </FormElement>
            <FormElement>
                {'SFW or NSFW (No extra charge): '}
				<div>
					<input type="radio" id={'sfw'} name={'is_nsfw'} value={'sfw'} checked={nsfw === 'sfw'}  onChange={(e) => setNsfw(e.target.value)} />
					<label for='sfw'>{' SFW'}</label>
				</div>
				<div>
					<input type="radio" id={'nsfw'} name={'is_nsfw'} value={'nsfw'} checked={nsfw === 'nsfw'}  onChange={(e) => setNsfw(e.target.value)} />
					<label for='nsfw'>{' NSFW'}</label>
				</div>
            </FormElement>
            <FormElement>
				{'Would you like a background? (extra rates apply)'}
				<div>
					<input type="radio" id='no' name={'wants_background'} value={'no'} checked={background === 'no'}  onChange={(e) => setBackground(e.target.value)} />
					<label for='no'>{' No'}</label>
				</div>
				<div>
					<input type="radio" id='yes' name={'wants_background'} value={'yes'} checked={background === 'yes'}  onChange={(e) => setBackground(e.target.value)} />
					<label for='yes'>{' Yes'}</label>
				</div>
            </FormElement>
            <FormElement>
                {'Reference #1: '}
                <input type="text" name="reference1"/>
            </FormElement>
            <FormElement>
                {'Reference #2: '}
                <input type="text" name="reference2"/>
            </FormElement>
            <FormElement>
                {'Commission description and other infomation'}
                <textarea name='description' required/>
            </FormElement>
            <FormElement>
                <Checkbox name='agree' required/>
                {'I have agreed to the '}<Link to="/tos">terms of service</Link>{' and am over the age of 19 and the age of majority in my jurisdiction.'}
            </FormElement>
            <SubmitButton type='submit' value={'Request Commission'}/>
        </Form>
    );
};

export default CommisionForm;
